<template>
    <b-modal
        :title="$t('storage.update_available')"
        modal-class="modal-update"
        v-model="isModalOpen"
        lazy
        hide-footer
    >
    	{{ $t('global.download_in_progress') }}
    </b-modal>
</template>

<script>
import { EventBus } from 'EventBus'

export default {
    name: 'PwaDownload',
    data: () => ({
        isModalOpen: false
    }),
    mounted() {
        EventBus.$on('SW::updateFound', () => {
            this.isModalOpen = true
        })
    },
}
</script>
